import React from 'react';
import './App.scss';
import CountDown from 'CountDown.js'
import Nav from 'Nav';
import Banniere from 'Banniere.js';

import laeti1 from './img/laeti1.jpg'
import laetipetit from './img/laetipetit.jpg'
import vincepetit from './img/vincepetit.jpg'
import vince1 from './img/vince1.jpg'
import itsamatch from './img/itsamatch.png'
import postalecat from './img/postalecat.jpg'
import postalesicorie from './img/postalesicorie.jpg'
import imageVoyage from './img/voyage.jpg'
import photoMaton from './img/photomaton.jpg'

import {
    Card,
    Container,
    Media,
    Image,
    Heading,
    Content,
    Icon,
    Block, Footer, Tile
} from 'react-bulma-components';
import CalendarLink from "./CalendarLink";
import MyMap from "./MyMap";

// Set event as an object
const event = {
    title: "Mariage Vincent et Laetitia",
    description: "Ne pas oublier les kleenex !!",
    location: " Cathédrale de la Sainte-Trinité, 12 Pl. Hardy de Lévaré, 53000 Laval, 369G+6H Laval",
    start: new Date("2022-10-01T15:00:00"),
    end: new Date("2022-10-01T23:59:00"),

};


function App() {
    return (
		<>
            <Nav />
           <Banniere />
            <section className='section'>
                <div className='columns is-centered' >
                    <div className='column has-text-centered is-half'>
                        <CountDown />
                        <CalendarLink label="Ajouter le mariage  à votre agenda" dateEvenement={event}/>
                    </div>
                </div>
            </section>

            <section id="nous" className='section'>
                <div className='columns is-centered is-vcentered'>
                    <div className='column  is-one-third '>
                        <Card>
                            <Card.Image
                                size="4by3"
                                src={vince1}
                            />
                            <Card.Content>
                                <Media>
                                    <Media.Item renderAs="figure" align="left">
                                        <Image
                                            rounded={true}
                                            size={128}
                                            alt="Vince"
                                            src={vincepetit}
                                        />
                                    </Media.Item>
                                    <Media.Item>
                                        <Heading size={4}>Vincent</Heading>
                                        <Heading subtitle size={6}>
                                            Ingénieur
                                        </Heading>
                                    </Media.Item>
                                </Media>
                                <Content>
                                    <div className="tags">
                                        <span className="tag"> <i className="fas fa-ruler-vertical" />181cm</span>
                                        <span className="tag"><i className="fas fa-wine-glass"/> avec modération</span>
                                        <span className="tag"><i className="fas fa-smoking" />jamais</span>
                                        <span className="tag"><i className="fa fa-camera"/>souvent</span>
                                        <span className="tag"><i className="fa fa-guitar"/>lala laï</span>
                                    </div>

                                        <p>Qualité : n'a pas le mal de mer...
                                        <br />
                                        Gros défauts : ne mange pas de fromage et ne boit pas de bière...
                                        <br />
                                        </p>

                                    <p><a className='button is-small' href='https://www.instagram.com/vin100paris/' target='_blank' rel="noreferrer" ><span className='icon'><i className="fab fa-instagram"/></span> <span>vin100paris</span></a>
                                    </p>


                                </Content>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className='column is-one-third '>
                        <Block className='has-text-centered has-white-background'><Image  src={itsamatch} alt="it's a match"/></Block>
                    </div>
                    <div className='column  is-one-third'>
                        <Card >
                            <Card.Image
                                size="4by3"
                                src={laeti1}
                            />
                            <Card.Content>
                                <Media>
                                    <Media.Item renderAs="figure" align="left">
                                        <Image
                                            rounded={true}
                                            size={128}
                                            alt="64x64"
                                            src={laetipetit}
                                        />
                                    </Media.Item>
                                    <Media.Item>
                                        <Heading size={4}>Laetitia</Heading>
                                        <Heading subtitle size={6}>
                                           Architecte d'interieur
                                        </Heading>

                                    </Media.Item>
                                </Media>
                                <Content>
                                    <div className="tags">
                                        <span className="tag"> <i className="fas fa-ruler-vertical"/> 167 cm</span>
                                        <span className="tag"><i className="fas fa-wine-glass" /> à l'occasion</span>
                                        <span className="tag"><i className="fas fa-smoking"/>jamais</span>
                                        <span className="tag"><i className="fa fa-anchor"/>asap</span>
                                        <span className="tag"><i className="fa fa-paint-brush"/>depuis toujours</span>
                                    </div>
                                    <em>
                                        "Les applications de rencontres c'est bien mais pas pour moi ..."  </em> <br />Laetitia <time
                                        dateTime="2020-12-31">31 Dec 2020</time>
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                </Content>
                            </Card.Content>
                        </Card>
                    </div>
                </div>
            </section>
		<section className="section has-background-info-light	  ">
		<Container>


        <Tile kind="ancestor"> 
            <Tile vertical>
                <Tile>
                    <Tile kind='parent'>
                         <Tile kind='child' renderAs='article' className='has-background-primary box has-text-white ' color="" >
                            <p id='info' className="title has-text-white title-joli">Bienvenue sur le site de notre Mariage !</p>
                            <p>Vous y trouverez toutes les informations dont vous aurez besoin le jour J.
                                    Nous comptons sur vous le 1er octobre !
                            </p>            
                         </Tile>
                    </Tile>
               
                </Tile>
                <Tile>
                    <Tile  kind='parent' vertical>
                        <Tile kind="child">  
                            <Card>
                                    <Card.Image size="4by3" src={postalecat} alt='Cathédrale de la Sainte-Trinité' />
                                    <Card.Content>
                                        <Media>
                                            <Media.Item>
                                                <p className="title is-4">Messe </p>
                                                <p className="subtitle is-6"> Cathédrale de la Sainte-Trinité <br /><a href='https://goo.gl/maps/oYVP43RMwC4Hidem7' target='_blank' rel="noreferrer" >
                                                    GPS :<span className='icon'><i className="fas fa-map-marker-alt"/></span> <span>369G+6H Laval</span></a></p>
                                            </Media.Item>
                                        </Media>
                                        <div className='content'>
                                            <p> La messe aura lieu à la cathédrale de Laval (en Mayenne). Il y a quelques places de stationnement devant la cathédrale, et vous pouvez aussi vous garer place des Acacias.  </p>
                                            <p>Adresse : 12 Pl. Hardy de Lévaré, 53000 Laval</p>
                                            

                                            <p> Un peu de culture ? <a href="https://fr.wikipedia.org/wiki/Cath%C3%A9drale_de_la_Sainte-Trinit%C3%A9_de_Laval"  target='_blank' rel="noreferrer">Lien Wikipedia</a></p>
                                        </div>
                                        <div className="content has-text-centered" >
                                            <a className='button is-small' href='https://goo.gl/maps/oYVP43RMwC4Hidem7' target='_blank' rel="noreferrer" >
                                                <span className='icon'><i className="fas fa-map-marker-alt" /></span> <span>Ouvrir dans google maps</span></a>
                                        </div>
                                    </Card.Content>
                                </Card>
                
                        </Tile>


                        <Tile  className='has-background-primary has-text-white box '>
                            <Content>
                                <p id='info' className="title has-text-white title-joli">Programme de la journée</p>
                                <p className="title is-4 has-text-white">Messe : </p>
                                <p> 1er octobre à 15h00 </p>
                                <p>Cathédrale de la Sainte-Trinité de Laval.</p>
<br />
                             <p className="title is-4 has-text-white">Cocktail et Dîner :</p>

                                     <p>À partir de 18 heures  </p>
                                        <p> La Sicorie, à Saint-Germain-le-Guillaume.  </p>
                                <p>Environ 25 minutes de route de Laval.</p>





                            </Content>
                        </Tile>

                    </Tile>

                    <Tile kind='parent' vertical>

                        <Tile kind="child"  >
                        <Content >
                              <p className="title is-5">Pour rejoindre le lieu de reception après la cérémonie :</p>

                            <div className=" has-text-centered" >
                                <a className='button'  href='https://goo.gl/maps/JjxKhfp6xrYGYzKW8' target='_blank' rel="noreferrer" >
                                <span className='icon'><i className="fas fa-car-side"/></span> <span>Itinéraire dans google maps</span></a>
                            </div>
                            <ul>
                                 <li> En arrivant à Saint-Germain-le-Guillaume prendre tout de suite à gauche D165 vers Chailland</li>
                                <li>500 m après la sortie du village prendre la première route à droite au calvaire blanc</li>
                                <li> Monter cette route sur 800 m et suivre les indications vers le parking</li>
                                </ul>

                     </Content>
                        </Tile>
                        <Tile kind="child"  className="has-background-warning-light box">
                            <Content>
                                <p className="title is-4">Pour les parents</p>

                                <p> Nous aimons beaucoup les enfants, et les vôtres en particulier, mais nous en comptons plus d’une centaine dans nos amis et parents.
                                    <br /> Nous ne pourrons malheureusement pas les accueillir pour notre mariage, mais nous pourrons ainsi mieux profiter de votre présence pendant cette belle journée.



                                </p>
                            </Content>
                        </Tile>
                        <Tile kind="child"> 
                            <Card>
                            <Card.Image size="4by3" src={postalesicorie} alt='La Sicorie' />
                            <Card.Content>
                                <Media>
                                    <Media.Item>
                                        <p className="title is-4">Réception</p>
                                        <p className="subtitle is-6"> La Sicorie
                                            <br />
                                            <a href='https://goo.gl/maps/gvXcsj9LYrbjTShJA' target='_blank' rel="noreferrer" >
                                                <span className='icon'><i className="fas fa-map-marker-alt" /></span> <span>6588+97 Saint-Germain-le-Guillaume</span></a></p>
                                    </Media.Item>
                                </Media>
                                <Content>
                                    <p>Après la cérémonie, retrouvez nous à la Sicorie, demeure du XVIème siècle restaurée au XIXème siècle. </p>
                                    <p> Et si vous tombez sous le charme de la Mayenne, revenez profiter de ses chambres d'hôtes.</p>
                                    <p> Plus d'info : <a href="https://la-sicorie.blog/"  target='_blank' rel="noreferrer" >https://la-sicorie.blog/</a></p>
                                </Content>
                                <div className="content has-text-centered" >
                                    <a className='button is-small' href='https://goo.gl/maps/gvXcsj9LYrbjTShJA' target='_blank' rel="noreferrer" >
                                        <span className='icon'><i className="fas fa-map-marker-alt" /></span> <span>Ouvrir dans google maps</span></a>

                                </div>
                            </Card.Content>
                            </Card>
                        </Tile>
                    </Tile>
                </Tile>
                
                <Tile>
                    <Tile kind='parent'  size="8">
                        <Tile kind='child' renderAs='article' className='box' >
                            <p   id="liste" className="title has-text-primary	">Notre Liste de mariage</p>
                                <div className="content has-text-primary	">
                                    <p>Si vous souhaitez nous aider à démarrer notre nouvelle vie, ou si vous souhaitez participer à notre voyage de noces, sachez que nous avons déposé une liste de mariage sur le site zankyou.com.
                                        Pour la visualiser, il vous suffit de cliquer sur le lien suivant : </p>


                                        <div className="content has-text-centered" >
                                            <a className='button is-primary' href="https://vincentetlaetitia.zankyou.com/fr/liste-de-mariage" target='_blank' rel="noreferrer" >
                                                <span className='icon'><i className="fas fa-gift"/></span> <span>Notre liste de mariage</span></a>
                                        </div>

                                    <p> Nous vous remercions de tout cœur !</p>
                                    <p> Vincent & Laetitia </p>
                                </div>

                        </Tile>
                    </Tile>
                    <Tile kind='parent'>
                        <Tile kind='child'  >

                            <div className="has-text-centered">

                            <figure className="image is-1by1">
                                <img src={imageVoyage} alt={"Voyage voyage"} />
                            </figure>
                            <a href="https://vincentetlaetitia.zankyou.com/fr/liste-de-mariage" target="_blank" rel="noreferrer" >Participez au voyage de noces... </a>
                            </div>
                       </Tile>
                        
                    </Tile>
                </Tile>

                <Tile>
                    <Tile kind='parent'  size="3">
                        <Tile kind='child' renderAs='article' className='' >
                           <img className='sticky has-shadow' src={photoMaton} alt={"photomaton"} />
                        </Tile>
                    </Tile>
                    <Tile kind='parent'>
                        <Tile kind='child' renderAs='article' className='box' >
                        <p className="title" id="dodo">Suggestions de logements</p>


          <div className="columns">
            <div className="column">
             <div className="content">
               <h3> Hôtels</h3> 
         
               <h4>Hôtel Marin</h4>
                  <p> <a href='https://www.marin-hotel.fr/fr' target="_blank" rel="noreferrer" >https://www.marin-hotel.fr/fr</a> <br />
                   102 Avenue Robert Buron    <br />
                    53000 LAVAL  <br />
                    02 43 53 09 68
                  </p>
             
                <h4>B&amp;B Hôtel Laval</h4>
               
                    <p> <a href='https://www.hotel-bb.com/fr/hotel/laval' target="_blank" rel="noreferrer">https://www.hotel-bb.com/fr/hotel/laval</a><br />
                    Rue Louis de Broglie <br />  
                    53810 Changé <br />
                    08 92 78 80 49
                        </p>
            
                <h4  >Hôtel du Maine</h4>
                <p>
                     <a href='https://www.hoteldumainelaval.com/' target="_blank" rel="noreferrer">https://www.hoteldumainelaval.com/</a><br />
                    98 avenue Robert Buron<br />
                    53000 Laval<br />
                    02 55 48 90 64
                </p>

           
                    <h4>Hôtel & Spa Perier du Bignon </h4>
                    <p><a href='https://www.hotelperierdubignon.fr/' target="_blank" rel="noreferrer" >https://www.hotelperierdubignon.fr/</a><br />
                    7 Rue Du Marchis<br />
                    53000 Laval<br />
                    02 43 49 90 00
                    </p>

                   <h4> Hôtel le Corbusson</h4>
                 <p> <a href="https://www.domaine-le-corbusson.com/" target="_blank" rel="noreferrer">https://www.domaine-le-corbusson.com/</a><br />
                    Hôtel  du Corbusson<br />
                    53940 Saint-Berthevin<br />
                    02 44 72 84 30
                    </p>

                    <h4> Western Hotel De Paris</h4>
                 <p><a href='https://www.booking.com/Share-4GCeB1' target="_blank" rel="noreferrer">https://www.booking.com/Share-4GCeB1</a><br />
                    22 Rue De La Paix<br />
                    53000 Laval<br />
                    02 43 53 76 20
                    </p>

                 <h4>Ibis Styles Laval Centre Gare</h4>
                 <p><a href="https://all.accor.com/hotel/B0L9/index.fr.shtml" target="_blank" rel="noreferrer">Site web</a><br />
                    8 Avenue Robert Buron<br />
                    02 43 67 19 25
                    </p>

                   <h4> Campanile Laval Ouest</h4>
                 <p> 9 rue Charles Toutain<br />
                    53000 Laval<br />
                    02 43 69 04 00
                       </p>
             </div>
            </div>
            <div className="column">
                <div className="content">
                    <h3>Chambre d'hôtes &amp; B&amp;B</h3>
                    <h4>Le Champ du Meslier</h4>
                    <p>
                        <a href='https://gite-chailland.fr' target="_blank" rel="noreferrer">https://gite-chailland.fr</a> <br/>
                        18, rue Saint-Germain  <br />
                        53420 Chailland <br />
                        06 78 96 84 07
                    </p>

                    <h4>Manoir de la Coudre</h4>
                    <p>
                        <a href="https://www.booking.com/Share-ixZr7Q" target="_blank" rel="noreferrer">https://www.booking.com/Share-ixZr7Q</a>  <br/>
                        2, La Coudre <br/>
                        53810 Changé <br/>
                        07 66 20 52 24
                    </p>
                    <h4>Chambre d’hôte La Roussière</h4>
                    <p>
                    <a href="https://www.booking.com/Share-OYcW2G" target="_blank" rel="noreferrer">https://www.booking.com/Share-OYcW2G</a>< br />
                    La Roussière<br/>
                    53410 Saint-Ouen-des-Toits <br/>
                    06 87 78 28 73
                    </p>

                    <h4>Le logis du Vassal</h4>
                    <p><a href="https://www.booking.com/Share-7LjKHy"  target="_blank" rel="noreferrer">https://www.booking.com/Share-7LjKHy</a> <br/>
                    13 Rue des Chevaux<br/>
                    53000 Laval
                    </p>
                    <h4>Manoir de Rouessé</h4>
                    <p><a href="https://manoir-rouesse.com/" target="_blank" rel="noreferrer">https://manoir-rouesse.com/</a> <br/>
                    4 rue Allende <br/>
                    53000 Laval<br/>
                    0243699403
                    </p>
                </div>
            </div>
        </div>
   </Tile>
                    </Tile>
                </Tile>
            </Tile>
        </Tile>

		</Container>

		</section>
            <section id={"venir"} >
                <Container>
                    <Heading className="mt-6">
                        Venir à notre mariage
                    </Heading>

                            <MyMap />
                </Container>
            </section>
            <section>
                <Container>

                </Container>
            </section>

            <Footer className=" mt-6">
                <div className="content has-text-left ">
                    <p>
                        VincentetLaetitia.com <br/>
                        <Icon className="fas fa-heart"/> <span> <a href="#nous"> Nous </a></span> <br />
                        <Icon className='fas fa-info-circle'/><span> <a href="#info" >Informations </a></span><br />
                        <Icon className='fas fa-map-signs'/> <span> <a href="#venir" >Venir</a></span><br />
                        <Icon className="fas fa-caravan"/><span> <a  href="#dodo" >Se loger</a></span><br />
                        <Icon className="fas fa-gift"/><span> <a  href="#liste"> liste de mariage</a></span>

                    </p>
                </div>
            </Footer>





        </>
	 );
}



export default App;
