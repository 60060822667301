import Countdown from 'react-countdown';

// Random component
const Completionist = () => <p className="title" >Le Jour J est arrivé ! </p>;

// Renderer callback with condition

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
	  return <div className='level is-mobile'>
                <div className='level-item has-text-centered'>
                        <div>
                            <p className="title" id="days">{days}</p>
                            <p>jours </p>
                        </div>
                    </div>

          <div className='level-item  has-text-centered'><div>
          <p className="title" id="hours">{hours}</p>
              <p>heures </p>
              </div>
          </div>
          <div className='level-item  has-text-centered'>
              <div>
              <p className="title" id="minutes">{minutes}</p>
              <p >minutes </p>
          </div></div>
          <div className='level-item  has-text-centered'><div>
              <p className="title" id="secondes">{seconds}</p>
              <p >secondes </p>
          </div>
          </div></div>


                   ;
  }
};

function CountDown() {
	return (
         <Countdown date={'2022-10-01T15:00:00'} renderer={renderer} />
      );
}


export default CountDown;