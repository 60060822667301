import React,{useState,useEffect} from 'react';
import {Icon } from 'react-bulma-components';


function Nav() {

    const [navbar, setNavbarTransparent] = useState(false)
    const [isBurgerActive, setBurgerActive] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 500) {
            setNavbarTransparent(true)
        } else {
            setNavbarTransparent(false)
        }
    }

    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })


    return (
        <nav className={`navbar is-fixed-top  ${navbar ? "is-primary" : "is-opaque"}`} role="navigation"
             aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item is-size-2 " href="#top"> Vincent et Laetitia </a>
                <a
                    onClick={() => {
                        setBurgerActive(!isBurgerActive)
                    }}
                    role="button" className={`navbar-burger burger ${isBurgerActive ? 'is-active' : ''}`}
                    aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                </a>

            </div>
            <div id="navbarBasicExample" className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}>
                <div className="navbar-end">
                    <NavbarItem icon='fas fa-heart' href="#nous" name='Nous '/>
                    <NavbarItem icon='fas fa-info-circle' href="#info" name='Informations'/>
                    <NavbarItem icon='fas fa-gift' href="#liste" name='Liste de mariage'/>
                    <NavbarItem icon='fas fa-caravan' href="#dodo" name='Se loger'/>
                    <NavbarItem icon='fas fa-map-signs ' href="#venir" name='Venir'/>


                </div>
            </div>

        </nav>
    );


    function NavbarItem(props) {
        return (
            <a className="navbar-item" href={props.href} onClick={() => {
                setBurgerActive(false)
            }}>
                <Icon className={props.icon}/> <span> {props.name}  </span>
            </a>
        );
    }
}



export default Nav;