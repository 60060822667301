import { ICalendar,GoogleCalendar,YahooCalendar,OutlookCalendar } from 'datebook'
import { Dropdown, Icon} from "react-bulma-components";


function CalendarLink(props) {

   /* const options: CalendarOptions = {
        title: props.event.title,
        location: props.event.location,
        description: props.event.description,
        start: new Date(props.event.start),
        end: new Date(props.event.end),
    }
    */
    function getCalendarLink(e)  {
        let typeCal = e;
        let linkCal = '' ;
        let options = props.dateEvenement;
        console.log(props);
        switch (typeCal) {
            case "google":
                const googleCalendar = new GoogleCalendar(options);
                linkCal = googleCalendar.render();
                break;
            case "outlook":
                const outlookCalendar = new OutlookCalendar(options);
                linkCal = outlookCalendar.render();
                break;
            case "office365":
                const officeCalendar = new OutlookCalendar(options);
                officeCalendar.setHost('office');
                linkCal = officeCalendar.render();
                break;
            case "yahoo":
                const yahooCalendar = new YahooCalendar(options);
                linkCal = yahooCalendar.render();
                break;
            default :
                const icalendar = new ICalendar(options);
                icalendar.download();
        }
       if ((typeCal==='google') || (typeCal==="office365")|| (typeCal==="yahoo" )|| (typeCal==="outlook" )){
           window.open(linkCal, "_blank");
       }
      /*  else {
            console.log(new DOMParser().parseFromString(linkCal, "text/html"));
            let filename = "download.ics";
            let blob = new Blob([linkCal], { type: "text/calendar;charset=utf-8" });

//https://github.com/jasonsalzman/react-add-to-calendar/blob/master/src/ReactAddToCalendar.js
            /****************************************************************
             // many browsers do not properly support downloading data URIs
             // (even with "download" attribute in use) so this solution
             // ensures the event will download cross-browser
             ****************************************************************/
        /*    let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        */
      //  console.log(linkCal);
    }



return (

        <Dropdown className='has-text-left' onChange={(e) => { getCalendarLink(e)}}
            label={props.label}  icon={<Icon><i aria-hidden="true" className="fas fa-calendar-plus" /> </Icon>}>
            <Dropdown.Item active='true' renderAs="a" value="apple" > <Icon className="fab fa-apple" /> <span> Calendrier Apple / ics </span></Dropdown.Item>
            <Dropdown.Item active='true' renderAs="a" value="google"> <Icon className="fab fa-google" /> <span>Calendrier google </span></Dropdown.Item>
            <Dropdown.Item active='true' renderAs="a" value="outlook"> <Icon className="fab fa-windows" /><span>Calendrier Outlook</span></Dropdown.Item>
            <Dropdown.Item active='true' renderAs="a" value="office365"> <Icon className="fab fa-windows" /><span>Calendrier Office 365</span></Dropdown.Item>
            <Dropdown.Item active='true' renderAs="a" value="yahoo"> <Icon className="fab fa-yahoo" /><span>Calendrier Yahoo</span></Dropdown.Item>
        </Dropdown>
  )

// window.open(CalendarLinkHelper.getCalendarLink(e,props.dateEvenement));

}


export default CalendarLink