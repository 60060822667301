import React from 'react'

import {MapContainer, TileLayer, Marker, Tooltip,useMa} from 'react-leaflet'
import L from 'leaflet'
import { GestureHandling } from "leaflet-gesture-handling"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"

import './MyMap.scss'

import cathedralImg from "./img/Laval_Cathedral.jpg"
import sicorieImg from "./img/sicorie.jpg"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);


function MyMap() {
    return (<MapContainer 
        center={[48.06805023587523, -0.7735026737855684]} 
        zoom={8} 
        scrollWheelZoom  
        gestureHandling
        whenCreated={map => setTimeout(() =>{ map.invalidateSize()}, 200)}
        >
    <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[48.06805023587523, -0.7735026737855684]} >
                <Tooltip direction={"right"}  offset={[0, 20]} permanent interactive>
                    <div >
                        <img
                            src={cathedralImg}
                            width="150px"
                            height="150px"
                            alt="Cathédrale"
                        />
                         <p> <a href='https://goo.gl/maps/oYVP43RMwC4Hidem7' target='_blank' rel="noreferrer" >La Cathédrale</a> </p>

                        </div>

                </Tooltip>
            </Marker>

    <Marker position={[48.2160182080536, -0.8342339445297855]}>
        <Tooltip direction={"left"} permanent offset={[-30,10]} interactive >
        <div >
            <img
                src={sicorieImg}
                width="150px"
                height="150px"
                alt="Sicorie"
            />


            <p> <a href='https://goo.gl/maps/gvXcsj9LYrbjTShJA' target='_blank' rel="noreferrer" >La Sicorie </a> </p>

            </div>

    </Tooltip>
    </Marker>

</MapContainer>
    )
}

export default MyMap