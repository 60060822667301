import React,{useState,useEffect} from 'react';
import {Parallax} from 'react-parallax' ;

import topImg0 from './img/vel0.jpg'
import topImg1 from './img/vel1.jpg'
import topImg2 from './img/vel2.jpg'
import topImg3 from './img/vel3.jpg'
import topImg4 from './img/vel4.jpg'
import topImg5 from './img/vel5.jpg'

function Banniere
() {

    const [imageAffichee, setImageAffichee] = useState(0);
    const images = [topImg1, topImg2, topImg3, topImg4,topImg5,topImg0];


    useEffect(() => {
        const interval = setInterval(() => {
            setImageAffichee(imageAffichee => ((imageAffichee + 1 ) % 5));

          }, 10000);
          return () => clearInterval(interval);
    }); 

   

    return (
        <Parallax className='topImage' id="top" blur={{min:-3,max:3}}  bgImage={images[imageAffichee]} strenght={800}   />
    )
}


export default Banniere;